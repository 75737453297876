// extracted by mini-css-extract-plugin
export var hscroll = "past-work-module--hscroll--P1Y8k";
export var pastWorkAppHr = "past-work-module--past-work-app-hr--auiz3";
export var pastWorkAppImageWrapper = "past-work-module--past-work-app-image-wrapper--rbSFN";
export var pastWorkAppLink = "past-work-module--past-work-app-link--mLiey";
export var pastWorkAppMobileImage = "past-work-module--past-work-app-mobile-image--hKG9y";
export var pastWorkAppPcImage = "past-work-module--past-work-app-pc-image--X15A8";
export var pastWorkAppTitle = "past-work-module--past-work-app-title--HCYd8";
export var pastWorkButton = "past-work-module--past-work-button--B8Zo7";
export var pastWorkContactText = "past-work-module--past-work-contact-text--Bog5S";
export var pastWorkContainer = "past-work-module--past-work-container--7Z4Uw";
export var pastWorkGraphicImage = "past-work-module--past-work-graphic-image--nmQhr";
export var pastWorkGraphicImageWrapper = "past-work-module--past-work-graphic-image-wrapper--fZyx7";
export var pastWorkGraphicTitle = "past-work-module--past-work-graphic-title--6x+yV";
export var pastWorkHr = "past-work-module--past-work-hr--2VtTf";
export var pastWorkLine = "past-work-module--past-work-line--oLqOG";
export var pastWorkLineIcon = "past-work-module--past-work-line-icon--+COZC";
export var pastWorkMailIcon = "past-work-module--past-work-mail-icon--JhhZG";
export var pastWorkSystemText = "past-work-module--past-work-system-text--NwgNy";
export var pastWorkTicker = "past-work-module--past-work-ticker--Dvy1A";
export var pastWorkTitle = "past-work-module--past-work-title--k2qR-";
export var pastWorkTitleJp = "past-work-module--past-work-title-jp--hN+CJ";