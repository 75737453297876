import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import sal from 'sal.js'
import Layout from '../components/layout'
import * as styles from '../styles/past-work.module.css'

const PastWork = () => {
  const breakpoints = useBreakpoint()

  React.useEffect(() => {
    sal({
      threshold: 0.25,
    })
  }, [])

  return (
    <Layout
      isHome={false}
      title="クリエイティブ制作実績"
      description="NEUTRALでは、Webサイト/Webアプリケーション/ロゴ/バナー/写真加工/動画編集/名刺/フライヤー/パンフレットなどのクリエイティブ制作を行なっています。"
    >
      <div className={styles.pastWorkContainer}>
        <h1 className={styles.pastWorkTitle}>Past Work</h1>
        <h2 className={styles.pastWorkTitleJp}>クリエイティブ制作実績</h2>
        <hr className={styles.pastWorkHr}/>
        <div className={styles.pastWorkTicker}>
          <ul>
            <li>Web application　/　</li>
            <li>Web site　/　</li>
          </ul>
          <ul>
            <li>Web application　/　</li>
            <li>Web site　/　</li>
          </ul>
          <ul>
            <li>Web application　/　</li>
            <li>Web site　/　</li>
          </ul>
          <ul>
            <li>Web application　/　</li>
            <li>Web site　/　</li>
          </ul>
          <ul>
            <li>Web application　/　</li>
            <li>Web site　/　</li>
          </ul>
        </div>
        <h2 className={styles.pastWorkAppTitle}>Mond</h2>
        <a className={styles.pastWorkAppLink} href="https://mond.nagoya" target="_blank" rel="noopener noreferrer">
          https://mond.nagoya
        </a>
        <div className={styles.pastWorkAppImageWrapper}>
          <div className={styles.pastWorkAppPcImage}
            data-sal="slide-right"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="Mond PC image"
              src="../images/mond-pc.png"
            />
          </div>
          <div className={styles.pastWorkAppMobileImage}
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="Mond Mobile image"
              src="../images/mond-mobile.png"
            />
          </div>
        </div>
        <hr className={styles.pastWorkAppHr}/>
        <h2 className={styles.pastWorkAppTitle}>Atmosphere Studio</h2>
        <a className={styles.pastWorkAppLink} href="https://atmospherestudio-amsterdam.com/" target="_blank" rel="noopener noreferrer">
          https://atmospherestudio-amsterdam.com/
        </a>
        <div className={styles.pastWorkAppImageWrapper}>
          <div className={styles.pastWorkAppPcImage}
            data-sal="slide-right"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="芝浜キンツギ部 PC image"
              src="../images/atmosphere-pc.png"
            />
          </div>
          <div className={styles.pastWorkAppMobileImage}
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="芝浜キンツギ部 Mobile image"
              src="../images/atmosphere-mobile.png"
            />
          </div>
        </div>
        <hr className={styles.pastWorkAppHr}/>
        <h2 className={styles.pastWorkAppTitle}>芝浜キンツギ部</h2>
        <a className={styles.pastWorkAppLink} href="https://shibahama-kintsugi.com/" target="_blank" rel="noopener noreferrer">
          https://shibahama-kintsugi.com/
        </a>
        <div className={styles.pastWorkAppImageWrapper}>
          <div className={styles.pastWorkAppPcImage}
            data-sal="slide-right"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="芝浜キンツギ部 PC image"
              src="../images/kintsugi-pc.png"
            />
          </div>
          <div className={styles.pastWorkAppMobileImage}
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="芝浜キンツギ部 Mobile image"
              src="../images/kintsugi-mobile.png"
            />
          </div>
        </div>
        <hr className={styles.pastWorkAppHr}/>
        <h2 className={styles.pastWorkAppTitle}>RICOH360</h2>
        <a className={styles.pastWorkAppLink} href="https://www.ricoh360.com/ja/tours/" target="_blank" rel="noopener noreferrer">
          https://www.ricoh360.com/ja/tours/
        </a>
        <div className={styles.pastWorkAppImageWrapper}>
          <div className={styles.pastWorkAppPcImage}
            data-sal="slide-right"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="RICOH360 PC image"
              src="../images/ricoh-pc.png"
            />
          </div>
          <div className={styles.pastWorkAppMobileImage}
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="RICOH360 Mobile image"
              src="../images/ricoh-mobile.png"
            />
          </div>
        </div>
        <hr className={styles.pastWorkAppHr}/>
        <h2 className={styles.pastWorkAppTitle}>RADIANT</h2>
        <a className={styles.pastWorkAppLink} href="https://radiant-cosme.com/" target="_blank" rel="noopener noreferrer">
          https://radiant-cosme.com/
        </a>
        <div className={styles.pastWorkAppImageWrapper}>
          <div className={styles.pastWorkAppPcImage}
            data-sal="slide-right"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="RADIANT PC image"
              src="../images/radiant-pc.png"
            />
          </div>
          <div className={styles.pastWorkAppMobileImage}
            data-sal="slide-left"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              alt="RADIANT Mobile image"
              src="../images/radiant-mobile.png"
            />
          </div>
        </div>
        <a className={styles.pastWorkLine} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
          <StaticImage
            className={styles.pastWorkLineIcon}
            alt="LINE icon"
            src="../images/icon-line.svg"
          />
          無料でLINE相談してみる
        </a>
        <p className={styles.pastWorkContactText}>または</p>
        <Link className={styles.pastWorkButton} to="/#contact">
          <StaticImage
            className={styles.pastWorkMailIcon}
            alt="Mail icon"
            src="../images/icon-mail.svg"
          />
          メールでお問い合わせ
        </Link>
        <div className={styles.pastWorkTicker} style={{backgroundColor: "#B67A59"}}>
          <ul>
            <li>Graphic design　/　</li>
            <li>Illustration　/　</li>
          </ul>
          <ul>
            <li>Graphic design　/　</li>
            <li>Illustration　/　</li>
          </ul>
          <ul>
            <li>Graphic design　/　</li>
            <li>Illustration　/　</li>
          </ul>
          <ul>
            <li>Graphic design　/　</li>
            <li>Illustration　/　</li>
          </ul>
          <ul>
            <li>Graphic design　/　</li>
            <li>Illustration　/　</li>
          </ul>
        </div>
        <h2 className={styles.pastWorkGraphicTitle}>Instagram thumbnail</h2>
        <div className={styles.pastWorkGraphicImageWrapper}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Graphic design image 1"
              src="../images/instagram-1.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Graphic design image 2"
              src="../images/instagram-2.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Graphic design image 3"
              src="../images/instagram-3.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Graphic design image 4"
              src="../images/instagram-4.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Graphic design image 5"
              src="../images/instagram-5.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Graphic design image 6"
              src="../images/instagram-6.jpg"
            />
          </div>
        </div>
        <h2 className={styles.pastWorkGraphicTitle}>YouTube thumbnail</h2>
        <div className={styles.pastWorkGraphicImageWrapper}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="YouTube Thumbnail design image 1"
              src="../images/youtube-1.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="YouTube Thumbnail design image 2"
              src="../images/youtube-2.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="YouTube Thumbnail design image 3"
              src="../images/youtube-3.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="YouTube Thumbnail design image 4"
              src="../images/youtube-4.jpg"
            />
          </div>
        </div>
        <h2 className={styles.pastWorkGraphicTitle}>Map</h2>
        <div
          className={styles.pastWorkGraphicImageWrapper}
          style={breakpoints.xs || breakpoints.sm || breakpoints.md ? {gridTemplateColumns: "repeat(1,1fr)"} : {gridTemplateColumns: "repeat(3,1fr)"}}
        >
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Map design image 1"
              src="../images/map-1.png"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Map design image 2"
              src="../images/map-2.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Map design image 3"
              src="../images/map-3.jpg"
            />
          </div>
        </div>
        <h2 className={styles.pastWorkGraphicTitle}>Flyer</h2>
        <div className={styles.pastWorkGraphicImageWrapper}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Flyer design image 1"
              src="../images/flyer-1.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Flyer design image 2"
              src="../images/flyer-2.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Flyer design image 3"
              src="../images/flyer-3.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Flyer design image 4"
              src="../images/flyer-4.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Flyer design image 5"
              src="../images/flyer-5.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Flyer design image 6"
              src="../images/flyer-6.jpg"
            />
          </div>
        </div>
        <h2 className={styles.pastWorkGraphicTitle}>Business Card / Logo</h2>
        <div className={styles.pastWorkGraphicImageWrapper}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Card design image 1"
              src="../images/business-card-1.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Card design image 2"
              src="../images/business-card-2.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Card design image 3"
              src="../images/business-card-3.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Card design image 4"
              src="../images/business-card-4.jpg"
            />
          </div>
        </div>
        <h2 className={styles.pastWorkGraphicTitle}>Sign board</h2>
        <div className={styles.pastWorkGraphicImageWrapper} style={{margin: "0 0 30px"}}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Sign board design image 1"
              src="../images/sign-board-1.jpg"
            />
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Sign board design image 2"
              src="../images/sign-board-2.jpg"
            />
          </div>
        </div>
        <div className={styles.pastWorkTicker} style={{backgroundColor: "#748CAA"}}>
          <ul>
            <li>YouTube　/　</li>
            <li>Video　/　</li>
          </ul>
          <ul>
            <li>YouTube　/　</li>
            <li>Video　/　</li>
          </ul>
          <ul>
            <li>YouTube　/　</li>
            <li>Video　/　</li>
          </ul>
          <ul>
            <li>YouTube　/　</li>
            <li>Video　/　</li>
          </ul>
          <ul>
            <li>YouTube　/　</li>
            <li>Video　/　</li>
          </ul>
        </div>
        <h2 className={styles.pastWorkAppTitle}>AMI HUIS (YouTube)</h2>
        <a className={styles.pastWorkAppLink} href="https://www.youtube.com/c/AMIHUIS" target="_blank" rel="noopener noreferrer">
          https://www.youtube.com/c/AMIHUIS
        </a>
        <div className={styles.pastWorkGraphicImageWrapper} style={{gridTemplateColumns: "repeat(1,1fr)"}}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
            style={{maxWidth: "650px"}}
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="YouTube AMI HUIS image 1"
              src="../images/amihuis-1.png"
            />
          </div>
        </div>
        <div className={styles.pastWorkGraphicImageWrapper} style={{margin: "20px 0 30px"}}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <a href="https://youtu.be/-OGgbl5Upfc" target="_blank" rel="noopener noreferrer">
              <StaticImage
                className={styles.pastWorkGraphicImage}
                alt="YouTube AMI HUIS image 2"
                src="../images/amihuis-2.jpg"
              />
            </a>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <a href="https://youtu.be/32pIB8iPesw" target="_blank" rel="noopener noreferrer">
              <StaticImage
                className={styles.pastWorkGraphicImage}
                alt="YouTube AMI HUIS image 3"
                src="../images/amihuis-3.jpg"
              />
            </a>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <a href="https://youtu.be/F_Xj77B-kRo" target="_blank" rel="noopener noreferrer">
              <StaticImage
                className={styles.pastWorkGraphicImage}
                alt="YouTube AMI HUIS image 4"
                src="../images/amihuis-4.jpg"
              />
            </a>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
          >
            <a href="https://youtu.be/bpzwWBJ5Yu4" target="_blank" rel="noopener noreferrer">
              <StaticImage
                className={styles.pastWorkGraphicImage}
                alt="YouTube AMI HUIS image 5"
                src="../images/amihuis-5.jpg"
              />
            </a>
          </div>
        </div>
        <div className={styles.pastWorkTicker}>
          <ul>
            <li>Robotic Process Automation　/　</li>
            <li>Google Apps Script　/　</li>
          </ul>
          <ul>
            <li>Robotic Process Automation　/　</li>
            <li>Google Apps Script　/　</li>
          </ul>
          <ul>
            <li>Robotic Process Automation　/　</li>
            <li>Google Apps Script　/　</li>
          </ul>
          <ul>
            <li>Robotic Process Automation　/　</li>
            <li>Google Apps Script　/　</li>
          </ul>
          <ul>
            <li>Robotic Process Automation　/　</li>
            <li>Google Apps Script　/　</li>
          </ul>
        </div>
        <h2 className={styles.pastWorkAppTitle}>業務効率化システム構築</h2>
        <p className={styles.pastWorkSystemText}>
          スプレッドシート、フォーム、カレンダーなど、<br/>
          Googleが提供する各種ツールを連携させ、<br/>
          <strong>運用費ゼロ</strong>の業務効率化システムを構築します。
        </p>
        <div className={styles.pastWorkGraphicImageWrapper} style={{gridTemplateColumns: "repeat(1,1fr)"}}>
          <div
            data-sal="slide-up"
            data-sal-duration="1000"
            data-sal-easing="ease-out-cubic"
            style={{maxWidth: "650px"}}
          >
            <StaticImage
              className={styles.pastWorkGraphicImage}
              alt="Google Apps Script image 1"
              src="../images/system-1.png"
            />
          </div>
        </div>
        <h2 className={styles.pastWorkAppTitle} style={{margin: "20px 0 0"}}>制作実例</h2>
        <p className={styles.pastWorkSystemText}>
          ✔︎ <strong>自動予約システム</strong><br/>
          ✔︎ <strong>自動勤怠管理システム</strong><br/>
          ✔︎ <strong>自動社内投票システム</strong>
        </p>
        <a className={styles.pastWorkLine} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
          <StaticImage
            className={styles.pastWorkLineIcon}
            alt="LINE icon"
            src="../images/icon-line.svg"
          />
          無料でLINE相談してみる
        </a>
        <p className={styles.pastWorkContactText}>または</p>
        <Link className={styles.pastWorkButton} to="/#contact">
          <StaticImage
            className={styles.pastWorkMailIcon}
            alt="Mail icon"
            src="../images/icon-mail.svg"
          />
          メールでお問い合わせ
        </Link>
      </div>
    </Layout>
  )
}

export default PastWork
